import styled from '@emotion/styled'
import { Spinner } from 'app/components/Common/Spinner'
import React, { memo } from 'react'

export interface Props {
  title?: string
  description?: string
}

export const BlockBooking = memo(function BlockBooking({
  title,
  description,
}: Props) {
  return (
    <Container id="form">
      <Wrapper>
        <Top>
          {title ? <Title>{title}</Title> : null}
          {description ? (
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          ) : null}
        </Top>
        <Booking>
          <Spinner variant="invert" />
          <ConvertoForm id="converto" />
        </Booking>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  position: relative;
  width: 100%;
  padding: 6.66vw;

  @media (max-width: 991px) {
    padding: 30px;
  }
`

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`

const Booking = styled.div`
  position: relative;
  width: 100%;
  margin-top: 4vw;

  @media (max-width: 991px) {
    margin-top: 30px;
  }
`

const Top = styled.div`
  text-align: center;
  max-width: 45%;
  margin: 0 auto;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`

const Title = styled.h2`
  font-size: 3vw;
  margin-bottom: 0.5vw;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fontFamily.heading};

  @media (max-width: 991px) {
    font-size: 28px;
    margin-bottom: 20px;
  }
`

const Description = styled.p``

const ConvertoForm = styled.div`
  width: 50%;
  min-width: 600px;
  position: relative;
  margin: 0 auto;
  z-index: 2;
  background: ${({ theme }) => theme.colors.variants.neutralLight5};

  @media (max-width: 1199px) {
    width: 100%;
    min-width: 0;
  }
`
